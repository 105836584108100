/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import InsertLink from '@material-ui/icons/InsertLink';

export const MainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
`;

export const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 400px;
  height: 50px;
  min-width: 64px;
  border: none;
  border-radius: 10px;
  padding: 6px 15px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 5px 15px rgba(211, 211, 211, 0.9);
`;

export const HeaderContainerStyled = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  padding: 6px 15px;
  height: 30px;
  justify-content: space-between;
`;

export const FacebookIcon = styled(Facebook)`
  color: ${({ theme }) => theme.black40};
  font-size: 25px;
`;

export const InstagramIcon = styled(Instagram)`
  color: ${({ theme }) => theme.black20};
  font-size: 25px;
`;

export const LinkIcon = styled(InsertLink)`
  color: ${({ theme }) => theme.black20};
  font-size: 25px;
`;

export const TextStyled = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.black20};
`;

export const PillTextStyled = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.white};
`;

export const HeadingTextStyled = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.black};
`;
