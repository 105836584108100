const paddingLeft = '40px';

export const COLUMNS = [
  {
    id: 'name',
    label: 'Nombre del documento',
    paddingLeft,
    width: 250,
    position: 1,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 5,
    position: 2,
  },
];

export const SCREEN_COLUMNS = [
  {
    id: 'order',
    label: 'Orden',
    paddingLeft,
    width: 5,
    position: 1,
  },
  {
    id: 'title',
    label: 'Título',
    paddingLeft: '',
    width: 250,
    position: 2,
  },
  {
    id: 'mandatory',
    label: 'Obligatoriedad',
    paddingLeft: '',
    width: 30,
    position: 3,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 5,
    position: 4,
  },
];
