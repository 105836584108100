/* eslint-disable import/prefer-default-export */

export const ICON_NAMES = {
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  WEB: 'WEB',
  OTHER: 'OTHER',
};
export const DOCUMENT_TYPES_NAMES = {
  TEXT: 'TEXT',
  DOCUMENT: 'DOCUMENT',
  MULTIMEDIA: 'MULTIMEDIA',
  MAP: 'LOCATION',
};
export const AVAILABLE_ICONS = [
  { value: ICON_NAMES.FACEBOOK, label: 'Facebook' },
  { value: ICON_NAMES.INSTAGRAM, label: 'Instagram' },
  { value: ICON_NAMES.WEB, label: 'Web' },
  { value: ICON_NAMES.OTHER, label: 'Otro' },
];
export const DOCUMENT_TYPES = [
  { value: DOCUMENT_TYPES_NAMES.MULTIMEDIA, label: 'Multimedia' },
  { value: DOCUMENT_TYPES_NAMES.TEXT, label: 'Texto' },
  { value: DOCUMENT_TYPES_NAMES.MAP, label: 'Mapa' },
  { value: DOCUMENT_TYPES_NAMES.DOCUMENT, label: 'Documento' },
];
