import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: '30vh',
    flexDirection: 'row',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
  },
  searchTextField: {
    boxShadow: '0px 3px 2px #00000026',
    width: 'calc(100% - 48px)',
    borderRadius: '10px',
    background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    opacity: 1,
    height: '33px',
    marginBottom: '16px',
  },
  dropdownContainer: {
    width: 'calc(100% - 68px)',
    marginBottom: '16px',
  },
  disabled: {
    opacity: 0.5,
  },
  searchIcon: {
    paddingLeft: '10px',
  },
  cancelIcon: {
    fontSize: '3rem',
    paddingLeft: '10px',

  },
  boxContainer: {
    width: '100%',
    display: 'flex',
  },
}));

export default useStyle;
