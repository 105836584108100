import React from 'react';
import PropTypes from 'prop-types';
import { ICON_NAMES } from '../../../../screens/Documentation/components/ActionForm/constants';
import {
  ContainerStyled,
  TextStyled,
  InstagramIcon,
  FacebookIcon,
  LinkIcon,
  MainContainerStyled,
  HeaderContainerStyled,
  HeadingTextStyled,
} from './styles';

const TextInputPreview = ({
  documentName,
  placeholder,
  iconName,
}) => {
  const iconNames = {
    [ICON_NAMES.INSTAGRAM]: <InstagramIcon />,
    [ICON_NAMES.FACEBOOK]: <FacebookIcon />,
    [ICON_NAMES.WEB]: <LinkIcon />,
    [ICON_NAMES.OTHER]: <LinkIcon />,
  };
  return (
    <MainContainerStyled>
      <HeaderContainerStyled>
        <HeadingTextStyled>{documentName}</HeadingTextStyled>
      </HeaderContainerStyled>
      <ContainerStyled>
        {iconNames[iconName]}
        <TextStyled>{placeholder}</TextStyled>
      </ContainerStyled>
    </MainContainerStyled>
  );
};

TextInputPreview.propTypes = {
  documentName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
};

export default TextInputPreview;
