import React from 'react';
import PropTypes from 'prop-types';
import MapPinImg from '../../../../assets/img/map-pin.png';
import MapResultImg from '../../../../assets/img/map-result.png';

import {
  ContainerStyled,
  TextStyled,
  SearchIcon,
  MainContainerStyled,
  HeaderContainerStyled,
  HeadingTextStyled,
  BoxStyled,
  ImgContainerStyled,
} from './styles';

const MapInputPreview = ({
  documentName,
  placeholder,
}) => (
  <MainContainerStyled>
    <HeaderContainerStyled>
      <HeadingTextStyled>{documentName}</HeadingTextStyled>
    </HeaderContainerStyled>
    <ContainerStyled>
      <SearchIcon />
      <TextStyled>{placeholder}</TextStyled>
    </ContainerStyled>
    <ImgContainerStyled>
      <BoxStyled
        component="img"
        src={MapResultImg}
        alt="text alt"
      />
      <BoxStyled
        component="img"
        src={MapPinImg}
        alt="text alt"
      />
    </ImgContainerStyled>
  </MainContainerStyled>
);

MapInputPreview.propTypes = {
  documentName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default MapInputPreview;
