import httpInstance from '../httpInstance';

const deleteRootnessDocument = async (documentId) => {
  const endpoint = `/back-office/v1/rootness-document/${documentId}`;
  let res;
  await httpInstance.delete(
    endpoint,
  ).then((dataResponse) => {
    res = dataResponse;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default deleteRootnessDocument;
