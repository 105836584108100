import httpInstance from '../httpInstance';

const uploadDocumentFiles = async (body) => {
  const endpoint = '/back-office/v1/rootness-document/upload-s3-document/';
  let res;
  await httpInstance.post(
    endpoint,
    body,
  ).then((dataResponse) => {
    res = dataResponse;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default uploadDocumentFiles;
