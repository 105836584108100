/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable quotes */
import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { ButtonStyled } from "./styles";
import PreviewContent from "./partials/PreviewContent/PreviewContent";

const PreviewModal = ({
  isOpen,
  title,
  setIsOpen,
  getValues,
  type,
  image,
  imageErrorHandler,
}) => (
  <Dialog open={isOpen} aria-labelledby="dialog" maxWidth="md" fullWidth>
    <DialogTitle id="dialog">{title}</DialogTitle>
    <DialogContent>
      <PreviewContent
        type={type}
        getValues={getValues}
        image={image}
        imageErrorHandler={imageErrorHandler}
      />
    </DialogContent>
    <DialogActions>
      <ButtonStyled
        error
        autoFocus
        onClick={() => {
          setIsOpen(false);
        }}
      >
        Cerrar
      </ButtonStyled>
    </DialogActions>
  </Dialog>
);

PreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  getValues: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageErrorHandler: PropTypes.func,
};

export default PreviewModal;
