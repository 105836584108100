/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-computed-key */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable dot-notation */
import React, { useState } from 'react';
import {
  Paper,
} from '@material-ui/core';
import useStyle from './useStyle';
import { InternalPage } from '../../Layouts';
import ProductCard from './components/productCard';
import paymentLink from '../../assets/img/link.png';
import billetap from '../../assets/img/billetap.png';
import mpos from '../../assets/img/mpos-only.png';
import ActionForm from './ActionForm/ActionForm';

const defaultData = {
  order: '',
  title: '',
  obligation: '',
};

const DocumentConfigByProductScreen = () => {
  const PRODUCTS = [
    { id: 'MPOS', name: 'Terminal Billet', image: mpos },
    { id: 'LINK', name: 'Link de pagos', image: paymentLink },
    { id: 'BILLETAP', name: 'BilleTap', image: billetap },
  ];
  const [productSelected, setProductSelected] = useState('');
  const [defaultValues, setDefaultValues] = useState(defaultData);
  const [popUpAction, setPopUpAction] = useState({
    open: false,
    title: '',
    data: null,
  });
  const classes = useStyle();

  const handleClick = (product) => {
    setProductSelected(product.id);
    setDefaultValues(defaultData);
    setPopUpAction({
      open: true,
      title: `Configurar ${product.name}`,
      data: null,
    });
  };

  return (
    <InternalPage loading={false} error={alert.type === 'error'}>
      <Paper className={classes.paper}>
        {PRODUCTS.map((product) => (
          <ProductCard
            text={product.name}
            onClick={() => handleClick(product)}
            key={product.id}
            selected={productSelected === product.id}
            image={product.image}
          />
        ))}
        {productSelected && (
        <ActionForm
          open={popUpAction.open}
          title={popUpAction.title}
          product={productSelected}
          defaultValues={defaultValues}
          onClose={() => {
            setPopUpAction({ open: false, title: '', data: null });
          }}
        />
        )}
      </Paper>
    </InternalPage>
  );
};

export default DocumentConfigByProductScreen;
