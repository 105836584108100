import httpInstance from '../httpInstance';

const getDocumentsByProduct = async (product) => {
  const endpoint = `/back-office/v1/rootedness-documentation-config/product-type/${product}`;
  let res;
  await httpInstance.get(
    endpoint,
  ).then((dataResponse) => {
    res = dataResponse;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getDocumentsByProduct;
