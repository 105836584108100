/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { SnackbarProvider } from 'notistack';
import {
  CustomModal,
  CustomTable, PreviewModal, Toaster,
} from '../../../../components';
import useStyle from './useStyle';
import { columns, DELETE_CONFIRM_MODAL_COPY } from './constants';
import deleteRootnessDocument from '../../../../services/documents/deleteDocuments';
import getDocumentById from '../../../../services/documents/getDocumentById';
import { DOCUMENT_TYPES_NAMES } from '../ActionForm/constants';

const DocumentsTable = ({
  enteredFilter,
  documents,
  fetchDocuments,
  setDefaultValues,
  setPopUpAction,
}) => {
  const classes = useStyle();
  const providerRef = useRef();
  const capitalize = (text) => (text ? text.charAt(0).toUpperCase() + text.slice(1) : text);
  const [documentsRow, setDocumentsRow] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [documentSelected, setDocumentSelected] = useState({});
  const [documentsRowCopy, setDocumentsRowCopy] = useState([]);
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);

  const filterInfoTable = (filter, codesMapped) => {
    const documentList = codesMapped != null ? codesMapped : documentsRowCopy;
    const filteredList = documentList.filter(
      (document) => (document?.name ?? '')
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
        || (document?.type ?? '')
          .toLowerCase()
          .includes(filter.toLowerCase()),
    );
    setDocumentsRow(filteredList);
  };

  const deleteDocument = async () => {
    const res = await deleteRootnessDocument(documentSelected.id).catch(() => setAlert({
      status: true,
      type: 'error',
      message: 'Ha ocurrido un error, intente nuevamente.',
    }));
    if (res?.status !== 200) {
      return setAlert({
        status: true,
        type: 'error',
        message: 'Ha ocurrido un error, intente nuevamente.',
      });
    }
    providerRef.current.enqueueSnackbar(
      'Se ha eliminado el documento exitosamente',
      {
        variant: 'success',
        anchorOrigin: {
          vertical: 'botom',
          horizontal: 'center',
        },
      },
    );
    setOpenAlertConfirm(false);
    await fetchDocuments();
  };
  const onPreviewHandler = async (documentId, type) => {
    let currentDocument = documents.find((doc) => doc.id === documentId);
    if (type === DOCUMENT_TYPES_NAMES.DOCUMENT || type === DOCUMENT_TYPES_NAMES.MULTIMEDIA) {
      try {
        const cachedData = localStorage.getItem(documentId);
        if (cachedData) {
          currentDocument = {
            ...currentDocument,
            infoComponent: { ...currentDocument.infoComponent, s3FilePath: cachedData },
          };
        } else {
          const response = await getDocumentById(documentId);
          currentDocument = response.data;
          localStorage.setItem(documentId, response.data?.infoComponent?.s3FilePath);
        }
      } catch (error) {
        setAlert({
          status: true,
          type: 'error',
          message: 'Ha ocurrido un error, intente nuevamente.',
        });
      }
    }
    setDocumentSelected(currentDocument);
    setOpenPreview(true);
  };
  const onImageError = async (documentId) => {
    localStorage.removeItem(documentId);
    onPreviewHandler(documentId, DOCUMENT_TYPES_NAMES.MULTIMEDIA);
  };

  const createActions = (documentId, type) => (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <IconButton
        edge="end"
        aria-label="Editar"
        size="small"
        onClick={() => {
          const currentDocument = documents.find((doc) => doc.id === documentId);
          setDefaultValues({
            id: documentId,
            placeHolder: currentDocument?.placeHolder,
            documentName: currentDocument?.label,
            documentType: currentDocument?.documentType,
            titleInfo: currentDocument?.infoComponent?.title,
            textInfo: currentDocument?.infoComponent?.description,
            iconPlaceholder: currentDocument?.inputIcon,
          });
          setPopUpAction({
            open: true,
            title: 'Editar documento',
            data: null,
          });
        }}
        classes={{ disabled: classes.disabled }}
      >
        <Tooltip title="Editar" aria-label="edit" placement="top">
          <EditIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
      <IconButton
        edge="end"
        aria-label="Eliminar"
        size="small"
        onClick={() => {
          setDocumentSelected(documents.find((doc) => doc.id === documentId));
          setOpenAlertConfirm(true);
        }}
        classes={{ disabled: classes.disabled }}
      >
        <Tooltip title="Eliminar" aria-label="edit" placement="top">
          <DeleteIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
      <IconButton
        edge="end"
        aria-label="Preview"
        size="small"
        onClick={() => onPreviewHandler(documentId, type)}
        classes={{ disabled: classes.disabled }}
      >
        <Tooltip title="Preview" aria-label="edit" placement="top">
          <VisibilityIcon className={classes.editIcon} />
        </Tooltip>
      </IconButton>
    </Box>
  );

  const setDocuments = (documentsParam) => {
    const documentsInfo = documentsParam?.map((document) => ({
      name: capitalize(document.label),
      type: capitalize(document.documentType),
      active: true,
      actions: createActions(document.id, capitalize(document.documentType)),
      id: document.id,
    }));

    setDocumentsRow(documentsInfo);
    setDocumentsRowCopy(documentsInfo);
    if (enteredFilter !== '') {
      filterInfoTable(enteredFilter, documentsInfo);
    }
  };

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  useEffect(() => {
    setDocumentsRow([]);
    setDocuments(documents);
  }, [documents]);
  return (
    <SnackbarProvider ref={providerRef}>
      <CustomTable data={documentsRow} columns={columns} />
      <PreviewModal
        isOpen={openPreview}
        setIsOpen={setOpenPreview}
        title="Vista previa"
        getValues={() => ({
          documentName: documentSelected?.label,
          documentType: documentSelected?.documentType,
          iconPlaceholder: documentSelected?.inputIcon,
          placeHolder: documentSelected?.placeHolder,
          titleInfo: documentSelected?.infoComponent?.title,
          textInfo: documentSelected?.infoComponent?.description,
        })}
        type={documentSelected?.documentType}
        image={documentSelected?.infoComponent?.s3FilePath}
        imageErrorHandler={() => onImageError(documentSelected.id)}
      />
      <CustomModal
        isOpen={openAlertConfirm}
        title="Confirmar"
        text={DELETE_CONFIRM_MODAL_COPY}
        firstButtonLabel="Eliminar"
        secondButtonLabel="Cancelar"
        firstButtonAction={() => deleteDocument()}
        setIsOpen={setOpenAlertConfirm}
        secondButtonAction={() => setOpenAlertConfirm(false)}
      />
      <Toaster
        show={alert.status}
        type={alert.type}
        text={alert.message}
        onClose={() => setAlert({ ...alert, status: false })}
      />
    </SnackbarProvider>
  );
};

export default DocumentsTable;
