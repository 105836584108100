/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { ButtonStyled } from './styles';

const CustomDialog = ({
  isOpen,
  title,
  text,
  okFunction,
  notOkFunction,
  openCloseFx,
  actionsDisabled,
  acceptButtonLabel,
  showCancelButton = true,
}) => (
  <Dialog
    open={isOpen}
    aria-labelledby="dialog"
  >
    <DialogTitle id="dialog">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {showCancelButton && (
      <ButtonStyled
        error
        autoFocus
        disabled={actionsDisabled}
        onClick={() => { notOkFunction(); openCloseFx(false); }}
      >
        Cancelar
      </ButtonStyled>
      )}
      <ButtonStyled
        autoFocus
        disabled={actionsDisabled}
        onClick={() => { okFunction(); openCloseFx(false); }}
      >
        {acceptButtonLabel || 'Aceptar'}
      </ButtonStyled>
    </DialogActions>
  </Dialog>
);

CustomDialog.propTypes = {
  acceptButtonLabel: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  openCloseFx: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  okFunction: PropTypes.func.isRequired,
  notOkFunction: PropTypes.func.isRequired,
  actionsDisabled: PropTypes.bool,
  showCancelButton: PropTypes.bool,
};

export default CustomDialog;
