/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-return */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React, {
  useEffect, useReducer, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  CustomInput,
  CustomSelect,
  CustomTable,
  CustomModal,
} from '../../../components';
import { Rules } from '../../../helpers/RHFRules';
import { Button } from '../ActionForm/styles';
import { reducer, initialState } from './reducers';
import {
  DOCUMENTS_COLUMNS, OBLIGATION, BUSSINESS_TYPE, OBLIGATORY, defaultValues, TABLE_VALUES,
} from '../constants';

const DocumentsModal = ({
  isOpen, closeModal, selectedScreenId, screens, documentList, addDocumentToScreen,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    control,
    errors,
    trigger,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({ defaultValues, mode: 'onChange' });

  const createActions = (docKey, callback) => (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <IconButton
        edge="end"
        aria-label="Eliminar"
        size="small"
        onClick={() => {
          callback(docKey);
        }}
      >
        <Tooltip title="Eliminar" aria-label="delete" placement="top">
          <DeleteIcon />
        </Tooltip>
      </IconButton>
    </Box>
  );

  const deleteDoc = (key) => {
    const filteredDocs = state.selectedScreen?.documents?.filter((doc) => doc.key !== key);
    const newScreen = { ...state.selectedScreen, documents: filteredDocs };
    dispatch({ type: 'SET_SELECTED_SCREEN', payload: newScreen });
  };

  const onPressDelete = (key) => {
    dispatch({ type: 'SET_DELETE_DIALOG', payload: true });
    dispatch({ type: 'SET_DOCUMENT_TO_DELETE', payload: key });
  };

  const documentsTableData = useMemo(() => state.selectedScreen?.documents?.map((doc) => ({
    order: doc.displayOrder,
    name: doc.label,
    businessType: doc.businessType,
    obligatoriedad: TABLE_VALUES[doc.obligatoriedad],
    active: true,
    actions: createActions(doc.key, onPressDelete),
    id: doc.id,
  })),
  [state.selectedScreen]);

  const handleAdd = () => {
    trigger();
    if (!isValid) {
      return;
    }
    const {
      docOrder,
      docObligation,
      documents,
      bussinessType,
      screenObligation,
      screenOrder,
      screenTitle,
    } = getValues();

    const orderRepeated = state.selectedScreen
      .documents
      .find((doc) => doc.displayOrder === Number(docOrder));
    if (orderRepeated) {
      dispatch({ type: 'SET_REPEATED', payload: true });
      return;
    }
    const newId = Date.now().toString();
    const newDoc = {
      id: newId,
      key: newId,
      displayOrder: docOrder,
      businessType: bussinessType,
      label: documents,
      obligatoriedad: docObligation,
    };
    const oldDocuments = state.selectedScreen.documents;
    const newDocs = [...oldDocuments, newDoc];
    const newScreen = { ...state.selectedScreen, documents: newDocs };
    dispatch({ type: 'SET_SELECTED_SCREEN', payload: newScreen });
    reset({
      ...defaultValues,
      screenObligation,
      screenOrder,
      screenTitle,
    });
  };

  const handleSaveScreen = () => {
    const {
      screenObligation,
      screenOrder,
      screenTitle,
    } = getValues();
    const newScreen = screens.map((screen) => (screen.id === selectedScreenId ? {
      ...state.selectedScreen,
      displayOrder: screenOrder,
      title: screenTitle,
      mandatory: screenObligation,
    } : screen));
    addDocumentToScreen(newScreen);
    closeModal();
  };

  const addKeyToDocs = (screen) => {
    const newDocs = screen?.documents?.map((docs, index) => {
      const key = (Date.now() + index).toString();
      return { ...docs, key };
    });
    return { ...screen, documents: newDocs };
  };

  useEffect(() => {
    const currentScreen = screens.find((screen) => screen.id === selectedScreenId);
    const newScreen = addKeyToDocs(currentScreen);
    dispatch({ type: 'SET_SELECTED_SCREEN', payload: newScreen });
    const formDefaultValues = {
      ...defaultValues,
      screenObligation: newScreen.mandatory,
      screenOrder: newScreen.displayOrder,
      screenTitle: newScreen.title,
    };
    reset(formDefaultValues);
    const mappedDocs = documentList.map((doc) => ({
      ...doc,
      value: doc.label,
    }));
    dispatch({ type: 'SET_DOCUMENTS_MAPPED', payload: mappedDocs });
  }, []);

  return (
    <Dialog
      maxWidth="lg"
      onClose={closeModal}
      aria-labelledby="Agregar documentos"
      open={isOpen}
      PaperProps={{
        style: { minWidth: '80%' },
      }}
    >
      <DialogTitle>Editar pantalla</DialogTitle>
      <DialogContent dividers>
        <form noValidate autoComplete="off">
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginTop: 30 }}>
                Pantalla
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <CustomInput
                  label="Orden"
                  name="screenOrder"
                  type="number"
                  control={control}
                  rule={Rules.numerico}
                  error={errors}
                  inputProps={{
                    maxLength: 3,
                  }}
                />
              </Grid>
              <Grid item xs={9}>
                <CustomInput
                  label="Titulo"
                  name="screenTitle"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 200,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CustomSelect
                  label="Obligatoriedad"
                  name="screenObligation"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={OBLIGATION}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginTop: 30 }}>
                Agregar Documentos
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <CustomInput
                label="Orden"
                name="docOrder"
                type="number"
                value={1}
                control={control}
                rule={Rules.numerico}
                error={errors}
                inputProps={{
                  maxLength: 3,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomSelect
                label="Obligatoriedad"
                name="docObligation"
                control={control}
                rule={Rules.required}
                error={errors}
                options={OBLIGATORY}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomSelect
                label="Documentos"
                name="documents"
                control={control}
                rule={Rules.required}
                error={errors}
                options={state.documentsMapped}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomSelect
                label="Tipo de negocio"
                name="bussinessType"
                control={control}
                rule={Rules.required}
                error={errors}
                options={BUSSINESS_TYPE}
              />
            </Grid>
            <Grid item xs={2}>
              <Button type="button" variant="contained" onClick={handleAdd}>
                Agregar
              </Button>
            </Grid>
            <Divider />
          </Grid>
          <Typography variant="h6" style={{ marginTop: 30 }}>
            Lista de Documentos
          </Typography>
          <Grid item xs={12} />
        </form>
        <CustomTable data={documentsTableData} columns={DOCUMENTS_COLUMNS} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="outlined" onClick={closeModal} color="primary">
          Cancelar
        </Button>
        <Button autoFocus onClick={handleSaveScreen} color="primary">
          Confirmar
        </Button>
      </DialogActions>
      <CustomModal
        firstButtonAction={() => { deleteDoc(state.documentToDelete); }}
        isOpen={state.showDeleteDialog}
        setIsOpen={(shouldOpen) => dispatch({ type: 'SET_DELETE_DIALOG', payload: shouldOpen })}
        title="Borrar documento"
        text="¿Esta seguro que quiere borra el documento?"
        firstButtonLabel="Eliminar"
        secondButtonLabel="Cancelar"
        secondButtonAction={() => { dispatch({ type: 'SET_DELETE_DIALOG', payload: false }); }}
      />
      <CustomModal
        firstButtonAction={() => dispatch({ type: 'SET_REPEATED', payload: false })}
        isOpen={state.showOrderRepeatedDialog}
        setIsOpen={(shouldShow) => dispatch({ type: 'SET_REPEATED', payload: shouldShow })}
        title="Orden de documento repetido"
        text="Escribe otro número de orden en que se mostrarán los documentos"
        firstButtonLabel="Aceptar"
      />
    </Dialog>
  );
};

export default DocumentsModal;

DocumentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedScreenId: PropTypes.number.isRequired,
  screens: PropTypes.array.isRequired,
  documentList: PropTypes.array.isRequired,
  addDocumentToScreen: PropTypes.func.isRequired,
};
