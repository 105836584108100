export const initialState = {
  selectedScreen: {},
  documentsMapped: [],
  documentsTableData: [],
  showDeleteDialog: false,
  showOrderRepeatedDialog: false,
  documentToDelete: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_SCREEN':
      return { ...state, selectedScreen: action.payload };
    case 'SET_DOCUMENTS_MAPPED':
      return { ...state, documentsMapped: action.payload };
    case 'SET_DELETE_DIALOG':
      return { ...state, showDeleteDialog: action.payload };
    case 'SET_REPEATED':
      return { ...state, showOrderRepeatedDialog: action.payload };
    case 'SET_DOCUMENT_TO_DELETE':
      return { ...state, documentToDelete: action.payload };
    default:
      return state;
  }
};
