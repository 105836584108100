/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid, Box, Modal,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import {
  CustomInput,
  Toaster,
  CustomSelect,
  Loading,
  PreviewModal,
  CustomModal,
} from '../../../../components';
import { Rules } from '../../../../helpers/RHFRules';
import { ActionPopUp } from '../../../../Layouts';
import { errorAlert } from '../../constants';
import { AVAILABLE_ICONS, DOCUMENT_TYPES, DOCUMENT_TYPES_NAMES } from './constants';
import {
  ButtonStyled,
  TextStyled,
  StyledInput,
  CircularProgressStyled,
  ImgStyled,
  ImageButtonStyled,
  StyledGapBox,
  ErrorTextStyled,
  InputImageButtonStyled,
} from './styles';
import saveRootnessDocument from '../../../../services/documents/saveDocuments';
import uploadDocumentFiles from '../../../../services/documents/uploadDocumentFiles';
import editRootnessDocument from '../../../../services/documents/putDocuments';
import { EDIT_CONFIRM_MODAL_COPY, WARNING_MODAL_COPY } from '../DataTable/constants';

const ActionForm = ({
  open, title, defaultValues, onClose, fetchDocuments,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [imageBarProgress, setImageBarProgress] = useState(false);
  const [onImageError, setOnImageError] = useState('');
  const [showImage, setShowImage] = useState(false);
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [imageUploaded, setImageUploaded] = useState({ file_id: '', uploaded_file: '' });
  const providerRef = useRef();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    trigger,
    formState: { isValid, errors },
  } = useForm({ defaultValues, mode: 'onChange' });

  const [imageFieldError, setImageFieldError] = useState(false);
  const documentType = watch('documentType');
  const isAnyMultimediaFieldRequired = useMemo(() => (
    !!watch('titleInfo') || !!watch('textInfo') || !!imageUploaded.uploaded_file
  ), [watch('titleInfo'), watch('textInfo'), imageUploaded.uploaded_file]);

  const areAllFieldsCompleted = useMemo(() => (
    !!watch('titleInfo') && !!watch('textInfo') && !!imageUploaded.uploaded_file
  ), [watch('titleInfo'), watch('textInfo'), imageUploaded.uploaded_file]);

  const formatLabelWithRequiredIndicator = (label) => (isAnyMultimediaFieldRequired ? `${label} *` : label);
  const isDocumentOrMultimedia = documentType === DOCUMENT_TYPES_NAMES.DOCUMENT
  || documentType === DOCUMENT_TYPES_NAMES.MULTIMEDIA;
  const editForm = !!defaultValues?.id;

  const removeSpaces = (fileName) => fileName.split(' ').join('_');

  const handleOnclose = () => {
    onClose();
  };

  const onFileUpload = (event) => {
    event.preventDefault();
    const { id } = event.target;
    const fileReader = new FileReader();
    const file = event.target.files[0];
    if (!file.name.toLowerCase().match(/\.(png|jpg|jpeg)$/)) {
      setOnImageError('Debe cargar un archivo valido (png, jpg o jpeg).');
      return false;
    }
    if (file.size > 2097152) {
      setOnImageError(
        'El tamaño del archivo supera el máximo permitido (2MB).',
      );
      return false;
    }
    setImageBarProgress(true);
    fileReader.onload = () => {
      setImageUploaded({ file_id: id, uploaded_file: fileReader.result });
    };
    setTimeout(() => {
      if (file && file.name !== '') {
        setImageBarProgress(false);
      }
    }, 2000);
    setImageFieldError(false);
    fileReader.readAsDataURL(file);
  };

  const getImageType = (fileName) => {
    if (!fileName.startsWith('data:image/')) return null;
    const parts = fileName.split(';')[0].split('/');
    const format = parts[1]?.toUpperCase();
    return ['PNG', 'JPG', 'JPEG'].includes(format) ? format : null;
  };

  const handleSave = async (dataParam) => {
    const {
      documentName, placeHolder, iconPlaceholder, titleInfo, textInfo,
    } = dataParam;
    setLoading(true);
    try {
      let responseImageResponse;
      if (isDocumentOrMultimedia && !!imageUploaded.uploaded_file) {
        responseImageResponse = await uploadDocumentFiles({
          file_name: removeSpaces(documentName),
          base64: imageUploaded.uploaded_file.split(',')[1],
          file_type: getImageType(imageUploaded.uploaded_file),
        });
        if (responseImageResponse.status !== 200) {
          throw new Error('Error al subir imagen al bucket');
        }
      }
      const infoComponent = (titleInfo && textInfo && responseImageResponse?.data) ? {
        title: titleInfo,
        description: textInfo,
        s3FilePath: responseImageResponse?.data,
      }
        : {};
      const payload = {
        label: documentName,
        placeHolder,
        documentType,
        inputIcon: iconPlaceholder,
        infoComponent,
        id: editForm ? defaultValues?.id : null,
      };
      let serviceResponse;
      if (editForm) {
        serviceResponse = await editRootnessDocument(payload);
      } else {
        serviceResponse = await saveRootnessDocument(payload);
      }

      if (serviceResponse.status !== 200) {
        throw new Error('Error al guardar documento');
      }
      setOpenAlertConfirm(false);
      await fetchDocuments();
      handleOnclose();
    } catch (e) {
      setAlert(errorAlert);
      setOpenAlertConfirm(false);
    } finally {
      setLoading(false);
    }
  };

  const handleValidate = async () => {
    if (isAnyMultimediaFieldRequired && !imageUploaded.uploaded_file) setImageFieldError(true);
    await trigger();
  };

  const handleSaveIfValid = async () => {
    await handleValidate();
    const hasRequiredFields = isDocumentOrMultimedia && isAnyMultimediaFieldRequired;
    const fieldsNotCompleted = hasRequiredFields && !areAllFieldsCompleted;

    if (fieldsNotCompleted) {
      return setOpenWarningModal(true);
    }

    if (isValid) {
      return handleSubmit(handleSave(getValues()));
    }
  };

  useEffect(() => {
    reset(defaultValues);
    setImageUploaded({ file_id: '', uploaded_file: '' });
  }, [open]);

  return (
    <SnackbarProvider ref={providerRef}>
      {loading ? <Loading /> : null}
      <ActionPopUp
        open={open}
        title={title}
        onClose={handleOnclose}
        onSave={editForm ? () => setOpenAlertConfirm(true) : () => handleSaveIfValid()}
        enabledControls
      >
        <form noValidate autoComplete="off">
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CustomInput
                label="Nombre del documento *"
                name="documentName"
                control={control}
                rule={Rules.required}
                error={errors}
                inputProps={{
                  maxLength: 40,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CustomInput
                label="Placeholder *"
                name="placeHolder"
                control={control}
                rule={Rules.required}
                error={errors}
                inputProps={{
                  maxLength: 40,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <ButtonStyled
                type="button"
                onClick={async () => {
                  if (!isValid) {
                    await handleValidate();
                    return;
                  }
                  if (isDocumentOrMultimedia && !imageUploaded.uploaded_file) {
                    return setImageFieldError(true);
                  }
                  setOpenPreview(true);
                }}
              >
                {' '}
                Previsualizar
                {' '}
                <VisibilityIcon />
              </ButtonStyled>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CustomSelect
                label="Tipo de documento *"
                name="documentType"
                control={control}
                rule={Rules.required}
                error={errors}
                options={DOCUMENT_TYPES}
              />
            </Grid>
            {documentType === DOCUMENT_TYPES_NAMES.TEXT && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomSelect
                  label="Icono placeholder *"
                  name="iconPlaceholder"
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  options={AVAILABLE_ICONS}
                />
              </Grid>
            )}
            {isDocumentOrMultimedia && (
              <>
                <Grid item xs={12} sm={12} md={4} lg={4} />
                <Grid item xs={12} sm={12} md={4} lg={4} />
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextStyled>
                    {`Modal informativo ${
                      documentType === DOCUMENT_TYPES_NAMES.DOCUMENT ? 'documento' : 'multimedia'
                    }`}
                  </TextStyled>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <CustomInput
                    label={formatLabelWithRequiredIndicator('Titulo Informativo')}
                    name="titleInfo"
                    control={control}
                    rule={Rules.requiredIfMultimedia(isAnyMultimediaFieldRequired)}
                    error={errors}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <CustomInput
                    label={formatLabelWithRequiredIndicator('Texto Informativo')}
                    name="textInfo"
                    control={control}
                    rule={Rules.requiredIfMultimedia(isAnyMultimediaFieldRequired)}
                    error={errors}
                    inputProps={{
                      maxLength: 200,
                    }}
                  />
                </Grid>
                <Grid container item direction="column" xs={12} sm={12} md={4} lg={4}>
                  <StyledGapBox display="flex">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label htmlFor="fileIcon">
                        <StyledInput
                          onChange={onFileUpload}
                          id="fileIcon"
                          accept=".png, .jpg, .jpeg"
                          type="file"
                        />
                        <InputImageButtonStyled
                          color="inherit"
                          variant="contained"
                          component="span"
                          endIcon={<AddCircle htmlColor="#50B940" />}
                          {...(imageFieldError ? { fieldmissing: 'true' } : {})}
                        >
                          {!!imageUploaded.uploaded_file && !imageBarProgress
                            ? 'Reemplazar imagen'
                            : 'Agregar imagen informativa'}
                        </InputImageButtonStyled>
                      </label>
                      {imageFieldError && <ErrorTextStyled>Campo obligatorio</ErrorTextStyled>}
                    </div>
                    <Box display="flex">
                      {!!onImageError && <p>{onImageError}</p>}
                      {!onImageError && imageBarProgress && (
                        <CircularProgressStyled />
                      )}
                      {!onImageError
                        && !imageBarProgress
                        && !!imageUploaded.uploaded_file && (
                          <ImageButtonStyled
                            type="button"
                            onClick={() => setShowImage(true)}
                            onKeyDown={(e) => (e.key === 'Enter' || e.key === ' '
                              ? setShowImage(true)
                              : null)}
                            aria-label="Ver imagen en tamaño grande"
                          >
                            <img
                              src={imageUploaded.uploaded_file}
                              alt="Vista previa de la imagen"
                              width={40}
                              height={40}
                            />
                          </ImageButtonStyled>
                      )}
                    </Box>
                  </StyledGapBox>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={4} lg={4} />
            <Grid item xs={12} sm={12} md={4} lg={4} />
          </Grid>
        </form>
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
        <CustomModal
          isOpen={openAlertConfirm}
          title="Confirmar"
          text={EDIT_CONFIRM_MODAL_COPY}
          firstButtonLabel="Continuar"
          secondButtonLabel="Cancelar"
          firstButtonAction={handleSaveIfValid}
          setIsOpen={setOpenAlertConfirm}
          secondButtonAction={() => setOpenAlertConfirm(false)}
        />
        <CustomModal
          isOpen={openWarningModal}
          title="Advertencia"
          text={WARNING_MODAL_COPY}
          firstButtonLabel="Aceptar"
          firstButtonAction={() => setOpenWarningModal(false)}
          setIsOpen={setOpenWarningModal}
        />
        {imageUploaded && (
          <Modal open={showImage} onClose={() => setShowImage(false)}>
            <ImgStyled
              src={imageUploaded.uploaded_file}
              alt="Imagen en tamaño completo"
              width={480}
              height={480}
            />
          </Modal>
        )}
      </ActionPopUp>
      <PreviewModal
        isOpen={openPreview}
        setIsOpen={setOpenPreview}
        title="Vista previa"
        getValues={getValues}
        type={documentType}
        image={imageUploaded.uploaded_file}
      />
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  fetchDocuments: PropTypes.func.isRequired,
};

export default ActionForm;
