/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import {
  CustomInput, Toaster, CustomSwitch, Loading, JustificationDialog,
} from '../../../../components';
import { Rules } from '../../../../helpers/RHFRules';
import { ActionPopUp } from '../../../../Layouts';
import putMerchantService from '../../../../services/paymentsLink/putMerchantService';
import putCommerceInfoService from '../../../../services/commerceInfo/putCommerceInfoService';
import getCommerceInfoService from '../../../../services/commerceInfo/getCommerceInfoService';
import CustomCheckbox from '../../../../components/Form/CustomCheckbox';
import getCommercialCategoryService from '../../../../services/commercialCategory/getCommercialCategoryService';
import { validateAmountWithLimits, validateAmount, getDisabledField } from './utils';

import {
  DEFAULT_ERROR,
  NON_EXISTENT_MCC_ERROR, MCC_DISABLED_ERROR,
} from './constants';
import UserContext from '../../../../contexts/UserContext';

const ActionForm = ({
  open,
  data,
  title,
  onClose,
  defaultValues,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [active, setActive] = React.useState(true);
  const [dataCommerce, setDataCommerce] = React.useState({});
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [formDataSaved, setFormDataSaved] = useState({});
  const providerRef = React.useRef();

  const { user: { username } } = useContext(UserContext);
  const {
    handleSubmit, control, errors, watch, setValue, reset,
  } = useForm({ mode: 'all', defaultValues });

  const showErrorAlert = (message) => setAlert({ status: true, type: 'error', message: message || DEFAULT_ERROR });

  const documentNumberValue = watch('documentNumber');
  const commercialNameValue = watch('commercialName');
  const merchantIdCardnetValue = watch('merchantIdCardnet');
  const cardnetTerminalIdValue = watch('cardnetTerminalId');
  const expirationDaysValue = watch('expirationDays');
  const mcc = watch('mcc');
  const justification = watch('justification')?.toUpperCase();
  const modalActionDisabled = !justification
  || (justification?.length < 20 || justification?.length > 200);

  const getCommerceData = async (documentNumberVal) => {
    setLoading(true);
    const dataResponse = await getCommerceInfoService(documentNumberVal.target.value);
    if (dataResponse.status === 200) {
      setLoading(false);
      setDataCommerce(dataResponse.data);
      setValue('commercialName', dataResponse.data.commercial_name);
    } else if (dataResponse.status === 404) {
      setLoading(false);
      setValue('documentNumber', '');
      return setAlert({ status: true, type: 'error', message: 'El RNC ingresado no está registrado. Confirma que lo escribiste correctamente e intenta nuevamente.' });
    } else {
      setLoading(false);
      setValue('documentNumber', '');
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
  };

  const showErrorMessageMCC = (message) => {
    setValue('mcc', '');
    showErrorAlert(message);
  };

  const handleSuccess = (commercialCategory) => {
    if (commercialCategory.enabled) {
      setValue('checkedItbis', commercialCategory.itbis);
    } else {
      showErrorMessageMCC(MCC_DISABLED_ERROR);
    }
  };

  const handleNonExistentMCC = () => {
    showErrorMessageMCC(NON_EXISTENT_MCC_ERROR);
    setValue('checkedItbis', false);
  };

  const handleDefaultError = () => {
    showErrorMessageMCC();
  };

  const getCommercialCategoryData = async (commercialCategoryInput) => {
    setLoading(true);
    try {
      const {
        data: commercialCategory,
        status,
      } = await getCommercialCategoryService(commercialCategoryInput.target.value);

      const statusHandler = {
        200: handleSuccess,
        404: handleNonExistentMCC,
        default: handleDefaultError,
      };

      const handleResponseStatus = statusHandler[status] || statusHandler.default;
      handleResponseStatus(commercialCategory);
    } catch (error) {
      handleDefaultError();
    }
    setLoading(false);
  };

  const createPaymentLink = async () => {
    const MerchantPaymentLinkBody = {
      MerchantId: dataCommerce.merchant_id,
      CardnetMerchantId: merchantIdCardnetValue,
      CardnetTerminalId: cardnetTerminalIdValue,
      Name: commercialNameValue,
      CurrencyCode: 'DOP',
      ExpirationDays: expirationDaysValue,
      AllowHoldPost: false,
      PostExceedPercent: 0.25,
      EnabledForPaymentLinks: active,
      EnabledForStaticLinks: active,
      EnabledForTransactions: active,
      AllowCustomerMessages: true,
      HasProfileImage: false,
    };
    const commerceInfoPaymentLinkBody = {
      commerce_id: dataCommerce.commerce_id,
      merchant_id_cardnet: merchantIdCardnetValue,
      active_payment_links: active,
    };
    const responseMerchant = await putMerchantService(MerchantPaymentLinkBody);
    if (responseMerchant?.status === 200) {
      const responseCommerceInfo = await putCommerceInfoService(
        commerceInfoPaymentLinkBody,
      );
      if (responseCommerceInfo?.status !== 200) {
        return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      }
      onClose();
    } else {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    return true;
  };

  const updatePaymentLink = async () => {
    setLoading(true);
    const dataResponse = await getCommerceInfoService(documentNumberValue);
    if (dataResponse.status === 200) {
      const MerchantPaymentLinkBody = {
        MerchantId: dataResponse.data.merchant_id,
        CardnetMerchantId: merchantIdCardnetValue,
        CardnetTerminalId: cardnetTerminalIdValue,
        Name: commercialNameValue,
        CurrencyCode: 'DOP',
        ExpirationDays: expirationDaysValue,
        AllowHoldPost: false,
        PostExceedPercent: 0.25,
        EnabledForPaymentLinks: active,
        EnabledForStaticLinks: active,
        EnabledForTransactions: active,
        AllowCustomerMessages: true,
        HasProfileImage: false,
      };
      const commerceInfoPaymentLinkBody = {
        commerce_id: dataResponse.data.commerce_id,
        merchant_id_cardnet: merchantIdCardnetValue,
        active_payment_links: active,
        backoffice_user_id: username,
        justification,
        mcc,
      };
      setLoading(false);
      const responseMerchant = await putMerchantService(MerchantPaymentLinkBody);
      if (responseMerchant?.status === 200) {
        const responseCommerceInfo = await putCommerceInfoService(
          commerceInfoPaymentLinkBody,
        );
        if (responseCommerceInfo?.status !== 200) {
          return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
        }
        onClose();
      } else {
        return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
      }
    } else {
      return setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
    return true;
  };

  const handleOnclose = () => {
    onClose();
  };
  const handleSave = async () => {
    try {
      if (data) {
        await updatePaymentLink();
      } else {
        await createPaymentLink();
      }
    } catch (error) {
      setAlert({ status: true, type: 'error', message: 'Ha ocurrido un error, intente nuevamente.' });
    }
  };
  const onSavePress = (formData) => {
    setFormDataSaved(formData);
    if (!data || data?.activePaymentLinks === active) {
      handleSave(formData);
    } else {
      setOpenAlertConfirm(true);
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    if (data && data != null) {
      setActive(data.activePaymentLinks);
    }
  }, [data]);

  return (
    <SnackbarProvider ref={providerRef}>
      {loading ? <Loading /> : null}
      <ActionPopUp
        open={open}
        data={data}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(onSavePress)}
        enabledControls
      >

        <>
          <div style={{ marginTop: 15 }}>&nbsp;</div>
          <form noValidate autoComplete="off">
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing={2}
            >

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="RNC/Cédula*"
                  name="documentNumber"
                  inputMode="numeric"
                  pattern="[0-9]*'"
                  type="number"
                  control={control}
                  rule={Rules.documentNumber}
                  error={errors}
                  onBlur={getCommerceData}
                  inputProps={{
                    maxLength: 11,
                    minLength: 9,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Nombre del comercio*"
                  name="commercialName"
                  disabled={getDisabledField(documentNumberValue, commercialNameValue)}
                  control={control}
                  rule={Rules.required}
                  error={errors}
                  inputProps={{
                    maxLength: 25,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="ID adquirencia*"
                  name="merchantIdCardnet"
                  disabled={getDisabledField(documentNumberValue, commercialNameValue)}
                  control={control}
                  rule={Rules.cardnetId}
                  error={errors}
                  inputProps={{
                    minLength: 9,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="ID de terminal*"
                  name="cardnetTerminalId"
                  disabled={getDisabledField(documentNumberValue, commercialNameValue)}
                  control={control}
                  rule={Rules.terminalId}
                  error={errors}
                  inputProps={{
                    minLength: 8,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Categoria comercial (MCC)*"
                  name="mcc"
                  inputMode="numeric"
                  pattern="[0-9]*'"
                  type="number"
                  control={control}
                  rule={Rules.mcc}
                  error={errors}
                  onBlur={getCommercialCategoryData}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomInput
                  label="Días de vigencia de links generados*"
                  name="expirationDays"
                  inputMode="numeric"
                  pattern="[0-9]*'"
                  type="number"
                  control={control}
                  rule={{
                    required: 'Campo obligatorio',
                    validate: validateAmountWithLimits(1, 999),
                  }}
                  error={errors}
                  helperText={
                      validateAmount(errors.amount
                        ? errors.amount.type
                        : '')
                    }
                  InputPropsParam={{
                    inputProps: { type: 'number', min: 1, max: 999 },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} />
              <Grid item xs={12} sm={12} md={4} lg={4} />

            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Paper variant="outlined" style={{ height: 40, width: 155 }}>
                <Box display="flex" style={{ paddingTop: 10 }} justifyContent="flex-start" alignSelf="center">
                  <FormControlLabel
                    control={(
                      <CustomSwitch
                        checked={active}
                        onChange={(e) => {
                          setActive(e.target.checked);
                        }}
                        name="activePaymentLinks"
                        color="primary"
                      />
                      )}
                    label="Estado"
                    labelPlacement="start"
                  />
                </Box>
                <Paper variant="outlined" style={{ height: 40, width: 155 }}>
                  <Box display="flex" style={{ paddingTop: 0 }} justifyContent="flex-start" alignSelf="center">
                    <CustomCheckbox
                      name="checkedItbis"
                      control={control}
                      label="Itbis"
                      error={errors}
                      disabled
                    />
                  </Box>
                </Paper>
              </Paper>
            </Grid>
            <div style={{ marginTop: 15 }}>&nbsp;</div>
          </form>
        </>

        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
      <JustificationDialog
        isOpen={openAlertConfirm}
        openCloseFx={setOpenAlertConfirm}
        title="Confirmar"
        text={`¿Está seguro que quiere ${
          data?.activePaymentLinks ? 'desactivar' : 'activar'
        } Link de Pago a este comercio?`}
        okFunction={() => handleSave({ ...formDataSaved, justification })}
        notOkFunction={() => setOpenAlertConfirm(false)}
        control={control}
        Rules={Rules}
        errors={errors}
        actionsDisabled={modalActionDisabled}
      />
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionForm;
