/* eslint-disable no-useless-escape */
/* eslint-disable import/prefer-default-export */
export const Rules = {
  email: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^(?!.*?\.\.)([A-Za-z0-9_\-.+]{3,})+@([A-Za-z0-9_\-.]{3,})+\.([A-Za-z]{2,})$/,
      message: 'Debe ingresar una dirección de correo válida, mínimo 10 caracteres',
    },
  },
  letrasTildes: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Z\u00C0-\u00FF ]*$/,
      message: 'Únicamente es permitido letras y tildes',
    },
  },
  numerico: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]*$/,
      message: 'El campo debe ser numérico.',
    },
  },
  percentage: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^([0-9]|[1-9][0-9]|100)$/,
      message: 'El campo debe ser numérico y menor o igual a 100.',
    },
  },
  phone: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]{10}$/,
      message: 'Debe contener 10 números',
    },
  },
  phoneExtension: {
    pattern: {
      value: /^[0-9]{1,3}$/,
      message: 'Debe contener máximo 3 números',
    },
  },
  emailDC: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}$/,
      message: 'Debe ingresar una dirección de correo válida',
    },
  },
  letrasNumeros: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Z0-9]*$/,
      message: 'El campo debe ser alfanumérico.',
    },
  },
  checkTerminosCondiciones: {
    required:
      'Debes aceptar la política de términos y condiciones y Autorización de tratamiento de datos.',
  },
  required: {
    required: 'Campo obligatorio',
  },
  requiredWhitoutMessage: {
    required: '',
  },
  inputsAddress: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^([0-9]){0,3}$/,
      message: 'El campo debe contener maximo 3 numeros',
    },
  },
  pasaporte: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^([a-zA-Z0-9]){5,15}$/,
      message: 'El campo debe ser alfanumérico, mínimo 5 caracteres.',
    },
  },
  documentNumber: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^([0-9]){9,11}$/,
      message: 'El campo debe contener mínimo 9 números y máximo 11 números.',
    },
  },
  mcc: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^([0-9]){3,4}$/,
      message: 'El campo debe contener mínimo 3 números y máximo 4 números.',
    },
  },
  cardnetId: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^([0-9]){9,100}$/,
      message: 'El campo debe contener mínimo 9 números',
    },
  },
  terminalId: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^([0-9]){8,100}$/,
      message: 'El campo debe contener mínimo 8 números',
    },
  },
  tipoDocumentoNIT: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]{9,9}$/,
      message: 'El campo Nit debe ser numérico, máximo 9 caracteres.',
    },
  },
  otrosDocumentos: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]{5,15}$/,
      message: 'El campo debe ser numérico, mínimo 5 caracteres.',
    },
  },
  letrasTildesMin2: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Z\u00C0-\u00FF ]{2,200}$/,
      message: 'Únicamente es permitido letras y tildes, mínimo 2 caracteres.',
    },
  },
  telCel: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]{7,10}$/,
      message: 'El campo debe ser numérico, mínimo 7 caracteres.',
    },
  },
  letrasTildesNumMin3: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Z\u00C0-\u00FF ]{3,200}$/,
      message: 'Únicamente es permitido letras y tildes, mínimo 3 caracteres.',
    },
  },
  DenySpecialCharactersLegalName: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[^'\\,"]{3,200}$/,
      message: 'La razón social contiene caracteres inválidos, mínimo 3 caracteres.',
    },
  },
  codigoValidacion: {
    required: 'Ingresa el código de validación',
    pattern: {
      value: /^[0-9]{6,6}$/,
      message: 'Ingresa un código numérico de 6 caracteres.',
    },
  },
  letrasTildesNumerosEspacioBlancoCaracteresEspeciales: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9a-zA-Z\u00C0-\u00FF #\-\s@$!%*?;#=.,;:_,/]*$/,
      message: 'Únicamente es permitido letras, tildes, números y algunos caracteres especiales',
    },
  },
  urlValidate: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
      message: 'El formato de la url no es correcto.(www.mitienda.com)',
    },
  },
  urlFacebook: {
    pattern: {
      value: /(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/,
      message: 'La url no cumple con el formato.(www.facebook.com/identificadorTienda)',
    },
  },
  urlInstagram: {
    pattern: {
      value: /(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/([\w\-\.]*)/,
      message: 'La url no cumple con el formato.(www.instagram.com/identificadorTienda)',
    },
  },
  justification: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^.{20,200}$/,
      message: 'El texto debe tener entre 20 y 200 caracteres.',
    },
  },
  requiredIfMultimedia: (isRequired) => (isRequired ? { required: 'Campo obligatorio' } : {}),
};
