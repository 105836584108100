import httpInstance from '../httpInstance';

const getAllDocuments = async () => {
  const endpoint = '/back-office/v1/rootness-document/?lastEvaluatedKey=""&limit=1000';
  let res;
  await httpInstance.get(
    endpoint,
  ).then((dataResponse) => {
    res = dataResponse;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default getAllDocuments;
