export const initialState = {
  alert: { status: false, type: '', message: '' },
  loading: false,
  tabSelected: 0,
  documents: [],
  screensData: [],
  showDialog: false,
  showDeleteDialog: false,
  selectedScreen: null,
  openDocumentModal: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ALERT':
      return { ...state, alert: action.payload };
    case 'SET_TAB':
      return { ...state, tabSelected: action.payload };
    case 'SET_DOCUMENTS':
      return { ...state, documents: action.payload };
    case 'SET_SCREENS':
      return { ...state, screensData: action.payload };
    case 'SET_DIALOG':
      return { ...state, showDialog: action.payload };
    case 'SET_DELETE_DIALOG':
      return { ...state, showDeleteDialog: action.payload };
    case 'SET_SELECTED_SCREEN':
      return { ...state, selectedScreen: action.payload };
    case 'SET_DOCUMENT_MODAL':
      return { ...state, openDocumentModal: action.payload };
    default:
      return state;
  }
};
