/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Divider } from '@material-ui/core';
import CropOriginal from '@material-ui/icons/CropOriginal';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import CancelRounded from '@material-ui/icons/CancelRounded';

export const MainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
`;

export const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  height: 50px;
  border-radius: 10px;
  padding: 6px 15px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 5px 15px rgba(211, 211, 211, 0.9);
`;

export const ContainerHelpModalStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 400px;
  height: 520px;
  margin-top: 20px;
  border-radius: 30px 30px 0 0;
  padding: 6px 15px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 5px 15px rgba(211, 211, 211, 0.9);
`;

export const HeaderContainerStyled = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  padding: 6px 15px;
  height: 30px;
  justify-content: space-between;
`;

export const PillStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: auto;
  height: 35px;
  border: none;
  border-radius: 35px;
  padding: 6px 15px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.black};
`;

export const PhotoIcon = styled(CropOriginal)`
  color: ${({ theme }) => theme.white};
  font-size: 20px;
`;

export const HelpIcon = styled(HelpOutlineOutlined)`
  color: #007BFF;
  font-size: 20px;
`;

export const CloseIcon = styled(CancelRounded)`
  color: ${({ theme }) => theme.black10};
  font-size: 20px;
  margin-top: 15px;
`;

export const TextStyled = styled.p`
  font-size: ${({ size }) => size || '12px'};
  font-weight: ${({ weight }) => weight || 'normal'};;
  color: ${({ white, theme }) => (white ? theme.white : theme.black)};
  margin-top: ${({ marginTop }) => marginTop || 'none'};
`;

export const DividerStyled = styled(Divider)`
  background-color: ${({ theme }) => theme.black10};
  height: 1px;
  margin-top: 15px;
  width: 100%;
`;

export const ImageStyled = styled.img`
  background-color: ${({ theme }) => theme.black10};
  border-radius: 25px;
  width: 250px;
  height: 350px;
  object-fit: contain;
`;
