import httpInstance from '../httpInstance';

const updateConfigDocuments = async (body) => {
  const endpoint = '/back-office/v1/rootedness-documentation-config';
  let res;
  await httpInstance.post(
    endpoint,
    body,
  ).then((dataResponse) => {
    res = dataResponse;
  }).catch((err) => {
    res = err.response;
  });
  return res;
};

export default updateConfigDocuments;
