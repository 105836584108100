/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ContentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 30px;
  background-color: ${({ theme }) => theme.black5};
`;
