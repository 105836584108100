/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { DialogContentText } from '@material-ui/core';
import { ContentContainerStyled } from './styles';
import UploadPhotoPreview from '../UploadPhotoPreview/UploadPhotoPreview';
import TextInputPreview from '../TextInputPreview/TextInputPreview';
import MapInputPreview from '../MapInputPreview/MapInputPreview';
import { DOCUMENT_TYPES_NAMES } from '../../../../screens/Documentation/components/ActionForm/constants';

const previewComponents = {
  [DOCUMENT_TYPES_NAMES.MULTIMEDIA]: ({
    // eslint-disable-next-line react/prop-types
    documentName, placeHolder, titleInfo, textInfo,
  }, image, imageErrorHandler) => (
    <UploadPhotoPreview
      documentName={documentName}
      placeholder={placeHolder}
      modalTitle={titleInfo}
      modalDescription={textInfo}
      image={image}
      imageErrorHandler={imageErrorHandler}
    />
  ),
  [DOCUMENT_TYPES_NAMES.TEXT]: ({
    // eslint-disable-next-line react/prop-types
    documentName, placeHolder, iconPlaceholder,
  }) => (
    <TextInputPreview
      documentName={documentName}
      placeholder={placeHolder}
      iconName={iconPlaceholder}
    />
  ),
  [DOCUMENT_TYPES_NAMES.DOCUMENT]: ({
    // eslint-disable-next-line react/prop-types
    documentName, placeHolder, titleInfo, textInfo,
  }, image, imageErrorHandler) => (
    <UploadPhotoPreview
      documentName={documentName}
      placeholder={placeHolder}
      modalTitle={titleInfo}
      modalDescription={textInfo}
      image={image}
      imageErrorHandler={imageErrorHandler}
    />
  ),
  [DOCUMENT_TYPES_NAMES.MAP]: ({
    // eslint-disable-next-line react/prop-types
    documentName, placeHolder,
  }) => (
    <MapInputPreview
      documentName={documentName}
      placeholder={placeHolder}
    />
  ),
};

const PreviewContent = ({
  getValues, type, image, imageErrorHandler,
}) => {
  const values = getValues();
  const renderPreview = previewComponents[type]
    ? previewComponents[type](values, image, imageErrorHandler)
    : null;

  return (
    <>
      <DialogContentText>
        Previsualización de componente en dispositivo móvil:
      </DialogContentText>
      <ContentContainerStyled>
        {renderPreview}
      </ContentContainerStyled>
    </>
  );
};

PreviewContent.propTypes = {
  getValues: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageErrorHandler: PropTypes.func,
};

export default PreviewContent;
