const paddingLeft = '40px';

export const COLUMNS = [
  {
    id: 'name',
    label: 'Nombre del documento',
    paddingLeft,
    width: 250,
    position: 1,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 5,
    position: 2,
  },
];

export const DOCUMENTS_COLUMNS = [
  {
    id: 'order',
    label: 'Orden',
    paddingLeft,
    width: 5,
    position: 1,
  },
  {
    id: 'name',
    label: 'Nombre del documento',
    paddingLeft: '',
    width: 250,
    position: 2,
  },
  {
    id: 'businessType',
    paddingLeft: '',
    label: 'Tipo de negocio',
    width: 20,
    position: 3,
  },
  {
    id: 'obligatoriedad',
    paddingLeft: '',
    label: 'Obligatoriedad',
    width: 20,
    position: 4,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 5,
    position: 5,
  },
];

export const errorMessage = 'Ha ocurrido un error, intente nuevamente.';
export const successMessage = 'El documento se ha guardado con éxito.';
export const errorAlert = { status: true, type: 'error', message: errorMessage };
export const successAlert = { status: true, type: 'success', message: successMessage };

export const PRODUCT_ID = {
  MPOS: 'TERMINAL_BILLET',
  LINK: 'LINK_DE_PAGO',
  BILLETAP: 'BILLET_TAP',
};

export const OBLIGATION = [
  { value: 'TODOS', label: 'Todos' },
  { value: 'OPCIONAL', label: 'Opcional' },
  { value: 'CUSTOM', label: 'Personalizado' },
  { value: 'ALMENOS_UNO', label: 'Al menos uno' },
];

export const TABLE_VALUES = {
  TODOS: 'TODOS',
  OPCIONAL: 'OPCIONAL',
  CUSTOM: 'PERSONALIZADO',
  ALMENOS_UNO: ' AL MENOS UNO',
  REQUERIDO: 'REQUERIDO',
  OPTIONAL: 'OPTIONAL',
};

export const BUSSINESS_TYPE = [
  { value: 'FISICO', label: 'Fisico' },
  { value: 'VIRTUAL', label: 'Virtual' },
  { value: 'AMBAS', label: 'Ambos' },
];

export const OBLIGATORY = [
  { value: 'REQUERIDO', label: 'Requerido' },
  { value: 'OPTIONAL', label: 'Opcional' },
];

export const defaultValues = {
  docOrder: '',
  docObligation: '',
  documents: '',
  bussinessType: '',
  screenObligation: '',
  screenOrder: '',
  screenTitle: '',
};
