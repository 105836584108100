/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ContainerStyled,
  TextStyled,
  PillStyled,
  PhotoIcon,
  MainContainerStyled,
  HeaderContainerStyled,
  HelpIcon,
  ContainerHelpModalStyled,
  CloseIcon,
  DividerStyled,
  ImageStyled,
} from './styles';

const UploadPhotoPreview = ({
  documentName,
  placeholder,
  modalTitle,
  modalDescription,
  image,
  imageErrorHandler,
}) => (
  <MainContainerStyled>
    <HeaderContainerStyled>
      <TextStyled size="14px" weight={500}>{documentName}</TextStyled>
      <HelpIcon />
    </HeaderContainerStyled>
    <ContainerStyled>
      <TextStyled>{placeholder}</TextStyled>
      <PillStyled>
        <TextStyled size="14px" weight={500} white>Subir foto</TextStyled>
        <PhotoIcon />
      </PillStyled>
    </ContainerStyled>
    <ContainerHelpModalStyled>
      <HeaderContainerStyled>
        <TextStyled size="16px" weight={500} marginTop="30px">{modalTitle}</TextStyled>
        <CloseIcon />
      </HeaderContainerStyled>
      <DividerStyled />
      <TextStyled size="14px">
        {modalDescription}
      </TextStyled>
      <ImageStyled src={image} onError={imageErrorHandler} />
    </ContainerHelpModalStyled>
  </MainContainerStyled>
);

UploadPhotoPreview.propTypes = {
  documentName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalDescription: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageErrorHandler: PropTypes.func,
};

export default UploadPhotoPreview;
