/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, {
  useEffect, useRef, useReducer, useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  Tab,
  Tabs,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import {
  CustomInput,
  Toaster,
  Loading,
  CustomModal,
  CustomSelect,
  CustomTable,
} from '../../../components';
import DocumentsModal from '../components/DocumentsModal';
import { Rules } from '../../../helpers/RHFRules';
import { ActionPopUp } from '../../../Layouts';
import TabPanel from '../components/TabPanel';
import {
  errorAlert, PRODUCT_ID, successAlert, OBLIGATION, TABLE_VALUES,
} from '../constants';
import DocumentsTab from '../components/documentsTab';
import getDocumentsByProduct from '../../../services/configDocuments/getDocumentsByProduct';
import updateConfigDocuments from '../../../services/configDocuments/postConfigDocuments';
import { Button } from './styles';
import { SCREEN_COLUMNS } from './constants';
import { reducer, initialState } from './reducers';

const ActionForm = ({
  open, title, defaultValues, onClose, product,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const providerRef = useRef();
  const productName = PRODUCT_ID[product] || '';
  const {
    control,
    reset,
    errors,
    trigger,
    getValues,
    formState: { isValid },
  } = useForm({ defaultValues, mode: 'onChange' });

  const createActions = (screenId) => (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <IconButton
        edge="end"
        aria-label="Eliminar"
        size="small"
        onClick={() => {
          dispatch({ type: 'SET_SELECTED_SCREEN', payload: screenId });
          dispatch({ type: 'SET_DELETE_DIALOG', payload: true });
        }}
      >
        <Tooltip title="Eliminar" aria-label="delete" placement="top">
          <DeleteIcon />
        </Tooltip>
      </IconButton>
      <IconButton
        edge="end"
        aria-label="Editar"
        size="small"
        onClick={() => {
          dispatch({ type: 'SET_SELECTED_SCREEN', payload: screenId });
          dispatch({ type: 'SET_DOCUMENT_MODAL', payload: true });
        }}
      >
        <Tooltip title="Edtar" aria-label="edit" placement="top">
          <EditIcon />
        </Tooltip>
      </IconButton>
    </Box>
  );

  const screenTableData = useMemo(() => state.screensData?.map((screen) => ({
    actions: createActions(screen.id),
    active: true,
    order: screen.displayOrder,
    id: screen.id,
    mandatory: TABLE_VALUES[screen.mandatory],
    title: screen.title,
  })), [state.screensData]);

  const setScreenValues = (screens) => {
    dispatch({ type: 'SET_SCREENS', payload: screens });
  };

  const addIdToData = (screens) => screens.map((screen, index) => {
    const id = Date.now() + index;
    return { ...screen, id };
  });

  const fetchDocuments = async () => {
    dispatch({ type: 'SET_LOADING', payload: true });
    if (productName !== '') {
      try {
        const { data: { documents: documentsData, screens: screensInfo }, status } = await getDocumentsByProduct(productName);
        if (status === 200) {
          dispatch({ type: 'SET_DOCUMENTS', payload: documentsData });
          const screensWithID = addIdToData(screensInfo);
          setScreenValues(screensWithID);
        } else {
          dispatch({ type: 'SET_DOCUMENTS', payload: [] });
          setScreenValues([]);
          if (status !== 404) dispatch({ type: 'SET_ALERT', payload: errorAlert });
        }
      } catch (error) {
        dispatch({ type: 'SET_ALERT', payload: errorAlert });
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    }
  };

  const handleOnclose = () => {
    onClose();
  };

  const saveChanges = async (payload) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      await updateConfigDocuments(payload);
      dispatch({ type: 'SET_ALERT', payload: successAlert });
    } catch (error) {
      dispatch({ type: 'SET_ALERT', payload: errorAlert });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const handleSave = async () => {
    const orders = new Set();
    let hasDuplicate = false;

    for (const screen of state.screensData) {
      const order = Number(screen.displayOrder);
      if (orders.has(order)) {
        hasDuplicate = true;
        break;
      }
      orders.add(order);
    }

    if (hasDuplicate) {
      dispatch({ type: 'SET_DIALOG', payload: true });
      return;
    }

    const payload = {
      producto: productName,
      documents: state.documents,
      screens: state.screensData,
    };
    await saveChanges(payload);
  };

  const handleAdd = async () => {
    trigger();
    if (!isValid) {
      return;
    }
    const { order, title: screenTitle, obligation } = getValues();
    const orderRepeated = state.screensData.find((screen) => screen.displayOrder === Number(order));
    if (orderRepeated) {
      dispatch({ type: 'SET_DIALOG', payload: true });
      return;
    }
    const id = Date.now();
    const screen = {
      id,
      displayOrder: order,
      title: screenTitle,
      mandatory: obligation,
      documents: [],
    };
    const newScreens = [...state.screensData, screen];
    setScreenValues(newScreens);
    reset(defaultValues);
  };

  const handleDelete = async (selectedScreenId) => {
    const filteredScreens = state.screensData.filter((screen) => screen.id !== selectedScreenId);
    setScreenValues(filteredScreens);
    dispatch({ type: 'SET_SELECTED_SCREEN', payload: null });
  };

  const handleTabChange = (_, newValue) => {
    dispatch({ type: 'SET_TAB', payload: newValue });
  };
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    fetchDocuments();
  }, [product]);

  return (
    <SnackbarProvider ref={providerRef}>
      {state.loading ? <Loading /> : null}
      <ActionPopUp
        open={open}
        title={title}
        onClose={handleOnclose}
        onSave={handleSave}
        enabledControls
      >
        <form noValidate autoComplete="off">
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12}>
              <Tabs
                value={state.tabSelected}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Documentos Existentes" {...a11yProps(0)} />
                <Tab label="Agregar Pantallas" {...a11yProps(1)} />
              </Tabs>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} />
            <Grid item xs={12} sm={12} md={4} lg={4} />
            <Grid item xs={12} sm={12} md={4} lg={4} />
            <Grid item xs={12}>
              {state.screensData !== null && (
              <DocumentsTab
                setAlert={(alert) => dispatch({ type: 'SET_ALERT', payload: alert })}
                setLoading={(isLoading) => dispatch({ type: 'SET_LOADING', payload: isLoading })}
                tabSelected={state.tabSelected}
                documents={state.documents}
                setDocuments={(docs) => dispatch({ type: 'SET_DOCUMENTS', payload: docs })}
                screens={state.screensData}
                setScreens={setScreenValues}
              />
              )}
              <TabPanel value={state.tabSelected} index={1}>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="stretch"
                  spacing={2}
                >
                  <Grid item xs={1}>
                    <CustomInput
                      label="Orden"
                      name="order"
                      type="number"
                      control={control}
                      rule={Rules.numerico}
                      error={errors}
                      inputProps={{
                        maxLength: 3,
                      }}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <CustomInput
                      label="Titulo"
                      name="title"
                      control={control}
                      rule={Rules.required}
                      error={errors}
                      inputProps={{
                        maxLength: 200,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <CustomSelect
                      label="Obligatoriedad"
                      name="obligation"
                      control={control}
                      rule={Rules.required}
                      error={errors}
                      options={OBLIGATION}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button type="button" variant="contained" onClick={handleAdd}>
                      Agregar
                    </Button>
                  </Grid>
                  { state.screensData && state.screensData.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid container style={{ marginTop: 50 }}>
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom style={{ marginLeft: 10 }}>Pantallas</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: 20 }}>
                      <CustomTable data={screenTableData} columns={SCREEN_COLUMNS} />
                    </Grid>
                  </Grid>
                  )}
                </Grid>
              </TabPanel>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} />
            <Grid item xs={12} sm={12} md={4} lg={4} />
          </Grid>
        </form>
        <CustomModal
          firstButtonAction={() => dispatch({ type: 'SET_DIALOG', payload: false })}
          isOpen={state.showDialog}
          setIsOpen={(isOpen) => dispatch({ type: 'SET_DIALOG', payload: isOpen })}
          title="Orden de pantalla repetido"
          text="Escribe otro numero de orden en que se mostrará la pantalla"
          firstButtonLabel="Aceptar"
        />
        <CustomModal
          firstButtonAction={() => handleDelete(state.selectedScreen)}
          isOpen={state.showDeleteDialog}
          setIsOpen={(isOpen) => dispatch({ type: 'SET_DELETE_DIALOG', payload: isOpen })}
          title="Borrar pantalla"
          text="¿Esta seguro que quiere borrar la pantalla?"
          firstButtonLabel="Eliminar"
          secondButtonLabel="Cancelar"
          secondButtonAction={() => dispatch({ type: 'SET_DELETE_DIALOG', payload: false })}
        />
        { state.openDocumentModal && (
        <DocumentsModal
          isOpen={state.openDocumentModal}
          closeModal={() => dispatch({ type: 'SET_DOCUMENT_MODAL', payload: false })}
          selectedScreenId={state.selectedScreen}
          screens={state.screensData}
          documentList={state.documents}
          addDocumentToScreen={setScreenValues}
        />
        )}
        <Toaster
          show={state.alert.status}
          type={state.alert.type}
          text={state.alert.message}
          onClose={() => dispatch({ type: 'SET_ALERT', payload: { ...state.alert, status: false } })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default ActionForm;
